import React from 'react';
import {
    AwesomeButton,
} from 'react-awesome-button';
import styles from 'react-awesome-button/src/styles/themes/theme-blue';
import {activateLocation, createAccount} from "../../lib/Fetch";

export default function ActivateButton(props) {

    async function activate() {
        console.log(props.data);
        if (window.confirm(`Scherm activeren voor locatie ${props.data.locationId}`)) {
            console.log('Activeren!');
            createAccount(props.data.username, props.data.locationId)
                .then(body => {
                    console.log('body', body);
                    return activateLocation(props.data);
                })
                .then(body => {
                    alert('body', JSON.stringify(body, null, 4));
                    if (body.error) {
                        return alert('Er ging iets fout, probeert het zo nog eens');
                    }
                    alert('Scherm geactiveerd');
                    props.reset();
                });
        } else {
            console.log('Nog even wat aanpassen....');
        }
    }

    return (
        <div className='App-ActivateButton'>
            <AwesomeButton
                type="primary" style={{...styles, width: '100%', fontSize: '1.1em'}}
                onPress={activate}
                disabled={props.disabled}>
                Activeren
            </AwesomeButton>
        </div>
    )
}
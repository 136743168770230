import React from 'react';
import Select from 'react-select';
import options from './themeOptions';

export default function ThemeSelector(props) {
    const opts = options.map(option => ({
        value: option.name,
        label: `${option.brand} - ${option.name}`
    }));

    return (
        <div className='App-Theme-Selector'>
            <p className={'label'}>Thema</p>
            <Select
                isLoading={false}
                className="basic-single"
                classNamePrefix="selectTheme"
                defaultValue={opts[0]}
                name="themeSelector"
                placeholder="Kies een thema..."
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                })}
                onChange={data => props.onChange(options.find(option => option.name === data.value).name)}
                options={opts}
            />
        </div>
    )
}
export default {
    get SERVER_URL() {
        if (process.env.REACT_APP_SERVER_URL)
            return process.env.REACT_APP_SERVER_URL;
        if (process.env.NODE_ENV === 'development')
            return 'http://localhost:8080';
        return 'https://energiescherm-server.isopgewekt.app';
    }
}


import React from 'react';
import {RadioGroup, ReversedRadioButton} from "react-radio-buttons";

export default function DefaultSetupSelector(props) {

    return (
        <div className='App-DefaultSetup-Selector'>
            <p className={'label'}>Setup soort</p>
            <RadioGroup onChange={ props.onChange } value='energiescherm'>
                <ReversedRadioButton value="energiescherm">
                    Energiescherm
                </ReversedRadioButton>
                <ReversedRadioButton value="legacykiosk">
                    Kiosk (Legacy)
                </ReversedRadioButton>
            </RadioGroup>
        </div>
    )
}
import React, {useState, useEffect} from 'react';
import './App.scss';
import QrReader from "./Components/QrReader";
import Header from "./Components/Header";
import LocationSelector from "./Components/LocationSelector";
import {getLocations} from "./lib/Fetch";
import ThemeSelector from "./Components/ThemeSelector";
import DefaultSetupSelector from "./Components/DefaultSetupSelector";
import UsernameInput from "./Components/UsernameInput";
import ActivateButton from "./Components/ActivateButton";
import themeOptions from './Components/ThemeSelector/themeOptions';

function App() {
    const [readerOpen, updateReaderOpen] = useState(false);
    const [locations, updateLocations] = useState([]);
    const [selectedLocation, updateSelectedLocation] = useState({});
    const [selectedBrand, updateBrand] = useState('Basisschool');
    const [qrData, updateQrData] = useState('');
    const [defaultSetup, updateDefaultSetup] = useState('energiescherm');
    const [username, updateUsername] = useState('');
    const [key, updateKey] = useState(1);

    function selectLocation(location) {
        updateSelectedLocation(location);
    }

    function reset() {
        updateKey(key + 1);
        updateReaderOpen(false);
        updateSelectedLocation({});
        updateBrand('Basisschool');
        updateQrData('');
        updateDefaultSetup('energiescherm');
        updateUsername('');
    }

    function activateDisabled() {
        return !selectedLocation.Name
            || qrData === ''
            || username === '';
    }

    useEffect(() => {
        getLocations()
            .then(body => {
                updateLocations(body.locations);
            })
    }, []);

    const {brand, theme} = themeOptions.find(option => option.name === selectedBrand);

    return (
        <div className="App">
            <Header toggleReaderOpen={() => updateReaderOpen(!readerOpen)}/>
            <div id="App-content">
                <QrReader onScan={updateQrData} data={qrData} open={readerOpen} closeReader={() => updateReaderOpen(false)}/>
                <LocationSelector
                    locations={locations} loading={locations.length === 0}
                    onChange={selectLocation} value={selectedLocation}
                    key={`LocationSelect-${key}`}
                />
                <UsernameInput location={selectedLocation} onChange={updateUsername} value={username}/>
                <ThemeSelector onChange={updateBrand} value={selectedBrand} key={`ThemeSelect-${key}`}/>
                <DefaultSetupSelector onChange={updateDefaultSetup} value={defaultSetup}/>
                <ActivateButton
                    reset={reset}
                    disabled={activateDisabled()}
                    data={{
                        screenCode: qrData,
                        locationId: selectedLocation.Id,
                        username,
                        brand,
                        theme,
                        defaultSetup,
                    }}
                />
            </div>
        </div>
    );
}

export default App;

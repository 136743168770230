import CONSTANTS from "../constants/CONSTANTS";

export const getLocations = () => {
    return fetch(`${CONSTANTS.SERVER_URL}/locations`, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => res.json());
};

export const generateUsername = location => {
    console.log('location', location);

    return fetch(`${CONSTANTS.SERVER_URL}/accounts/generate-username/${location.Id}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => res.json());
};

export const createAccount = (username, locationId) => {
    return fetch(`${CONSTANTS.SERVER_URL}/accounts/generate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({username, locationId}),
    }).then(res => res.json());
}

export const activateLocation = data => {
    return fetch(`${CONSTANTS.SERVER_URL}/energyscreens/activate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(res => res.json());
};
import React, {Fragment, useState} from 'react';
import Reader from 'react-qr-reader'

export default function QrReader(props) {
    const [cameraPermission, updateCameraPermission] = useState(false);

    const handleScan = data => {
        if (!data)
            return;
        props.onScan(data);
        props.closeReader();
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && !cameraPermission) {
        // Not adding `{ audio: true }` since we only want video now
        navigator.mediaDevices.getUserMedia({video: true}).then(function (stream) {
            //video.src = window.URL.createObjectURL(stream);
            updateCameraPermission(true);
        });
    }

    return (
        <Fragment>
            <div className='App-QrReader'>
                {props.open ?
                    <Reader
                        delay={300}
                        onError={error => alert(error)}
                        onScan={handleScan}
                        style={{width: '100%'}}
                    /> : <Fragment/>
                }
                <p className={'label'}>QRCode energiescherm</p>
                <input value={props.data} disabled={true} placeholder={'Scan de qrcode...'}/>
            </div>
        </Fragment>
    )
}
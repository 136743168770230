import React, {useEffect} from 'react';
import {generateUsername} from "../../lib/Fetch";

export default function UsernameInput({location, value, onChange}) {


    useEffect(() => {
        if (location.Name)
            generateUsername(location)
                .then(body => {
                    console.log('body!', body);
                    onChange(body.accounts);
                });
    }, [location, onChange]);

    return (
        <div className='App-Username-Input'>
            <p className={'label'}>Gebruikersnaam</p>
            <input value={value} disabled={true} placeholder={'Gebruikersnaam'}/>
        </div>
    )
}
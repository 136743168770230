import React from 'react';
import {CameraAlt} from '@material-ui/icons';

function Header(props) {
    return (
        <header className="App-header">
            <div className="flex3 justify-center">
                <h1>Energiescherm activatie</h1>
            </div>
            <div className="flex1 justify-center">
                <CameraAlt onClick={props.toggleReaderOpen}/>
            </div>
        </header>
    );
}

export default Header;

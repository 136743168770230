import React from 'react';
import Select from 'react-select';


export default function LocationSelecter(props) {
    return (
        <div className='App-Location-Selector'>
            <p className={'label'}>Locatie</p>
            <Select
                isLoading={props.loading}
                className="basic-single"
                classNamePrefix="select"
                name="locationsSelector"
                placeholder="Kies een locatie..."
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                })}
                onChange={data => props.onChange(props.locations.find(location => location.Id === data.value))}
                options={props.locations.map(location => ({
                    value: location.Id,
                    label: `${location.Id} - ${location.Name}`
                }))}
            />
        </div>
    )
}